<template>
  <div class="flex flex-column pb3 form-spacing">
    <label class="pb2 font-w2">{{ title }}</label>
    <select
      class="form-sign"
      v-model="state.inputValue"
      :name="name"
      :id="name"
      style="padding: 15px"
      @change="onChange(state.inputValue, name)"
    >
      <slot name="child"></slot>
    </select>
  </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'SelectOption',
    props: {
        title: String,
        type: String,
        name: String,
        placeholder: String,
        required: Boolean,
        onChange: Function,
    },

    setup() {
        const state = reactive({
            inputValue: '',
        })

        return { state }
    },
}
</script>

<style scoped></style>
