<template>
    <settings-wrapper>
        <template v-slot:child>
            <form 
                class="box-border w-60-l w-90 center mt4 pa4" 
                style="background: #fbfcfe" 
                @submit.prevent="onSubmit"
            >
                <div class="flex flex-wrap justify-between">
                    <InputBox
                        :name="'orgName'"
                        :title="'Business Name *'"
                        :on-change="onChange"
                        :required="true"
                        :placeholder="'Enter your business name'"
                        style="width: -webkit-fill-available"
                    />

                    <!-- <InputBox
                        :name="'phoneNumber'"
                        :title="'Phone Number *'"
                        :on-change="onChange"
                        :required="true"
                        :placeholder="'Enter the phone number you want on your receipts/invoices'"
                        :type="'phone'"
                    /> -->
                     <div class="tel-div">
                                <div class="b pb2">Phone Number *</div>
                                <vue-tel-input
                                    :type="'tel'"
                                    :placeholder="'Enter your phone number'"
                                    :id="'phone'"
                                    class="form-sign2"
                                    :name="'phone'"
                                    mode="international"
                                    :defaultCountry="'NG'"
                                    :required="true"
                                    v-model="state.phoneNumber"
                                ></vue-tel-input>
                            </div>

                    <div class="flex flex-column pb3 form-spacing">
                        <label class="pb2">Business Email *</label>
                        <div class="input-group">
                            <input
                                class="form-sign w-100"
                                v-model="state.orgEmail"
                                @change="validateEmail"
                                type="email"
                                name="email"
                                placeholder="Enter the email you want on your receipts/invoices"
                                id="email"
                            />
                            <span v-if="state.isEmailValid && !state.isValidatingEmail" class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-check" class="fa-2x text-success" />
                            </span>
                            <span v-else-if="state.isEmailValid === false && state.orgEmail !== ''" class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-remove" class="fa-2x text-danger" />
                            </span>
                            <span v-else-if="state.isValidatingEmail" class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-circle-notch" class="fa-2x fa-spin" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap justify-between">
                    <div class="flex flex-column pb3 form-spacing">
                        <label class="pb2 font-w2">Type of business *</label>
                        <select class="form-sign" v-model="state.business" style="padding: 15px" required>
                            <option value="">Select an option</option>
                            <option value="Sole Trader/Business Name">Sole Trader/Business Name</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Limited Liability Company">Limited Liability Company</option>
                            <option value="Non Profit">Non Profit</option>
                        </select>
                    </div>

                    <div class="flex flex-column pb3 form-spacing">
                        <label class="pb2 font-w2">What does your business do *</label>
                        <select class="form-sign" v-model="state.typeOfBusiness" style="padding: 15px" required>
                            <option value="">Select what best describes your business</option>
                            <option value="Accommodation and Food Services">Accommodation and Food Services</option>
                            <option value="Administrative and Support Services">
                                Administrative and Support Services
                            </option>
                            <option value="Arts and Recreation Services">Arts and Recreation Services</option>
                            <option value="Construction/Builder">Construction/Builder</option>
                            <option value="Creative Arts & Media Production">Creative Arts & Media Production</option>
                            <option value="Education and Training">Education and Training</option>
                            <option value="Farming, forestry and fishing">Farming, forestry and fishing</option>
                            <option value="Fashion">Fashion</option>
                            <option value="Financial services & insurance">Financial services & insurance</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="Medical / Health Care / Community services">
                                Medical / Health Care / Community services
                            </option>
                            <option value="Personal, Beauty, Wellbeing and other services">
                                Personal, Beauty, Wellbeing and other services
                            </option>
                            <option value="Professional Services (e.g. Legal, Accounting, Marketing, Consulting)">
                                Professional Services (e.g. Legal, Accounting, Marketing, Consulting)
                            </option>
                            <option value="Property Operators and Real Estate services">
                                Property Operators and Real Estate services
                            </option>
                            <option value="Rental & Hiring services (non Real Estate)">
                                Rental & Hiring services (non Real Estate)
                            </option>
                            <option value="Repair and Maintenance (Automotive & Property)">
                                Repair and Maintenance (Automotive & Property)
                            </option>
                            <option value="Retail Trade (Food & Beverage)">Retail Trade (Food & Beverage)</option>
                            <option value="Retail Trade & Ecommerce (Non-Food)">
                                Retail Trade & Ecommerce (Non-Food)
                            </option>
                            <option value="Technology / Telecommunications services">
                                Technology / Telecommunications services
                            </option>
                            <option value="Trades work (e.g. Plumber, Carpenter, Electrician)">
                                Trades work (e.g. Plumber, Carpenter, Electrician)
                            </option>
                            <option value="Transport, Logistics, Postal, Warehousing">
                                Transport, Logistics, Postal, Warehousing
                            </option>
                            <option value="Wholesale Trade">Wholesale Trade</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                </div>
                <div class="flex flex-wrap justify-between">
                    <div class="flex flex-column pb3 form-spacing">
                        <label class="pb2 font-w2">Last day of financial year (optional)</label>
                        <input id="lastFinAccYear" class="form-sign" type="date" v-model="state.lastFinAccYear" />
                    </div>
                    
                    <div class="flex flex-column pb3 form-spacing">
                        <label class="pb2 font-w2">Business Category</label>
                        <select
                            class="form-sign"
                            v-model="state.orgCategory"
                            name="orgCategory"
                            id="orgCategory"
                            style="padding: 15px"
                        >
                            <option value="">Select an option</option>
                            <option value="Service">I offer services</option>
                            <option value="Product">I make or sell products</option>
                        </select>
                    </div>
                </div>
                <div class="flex justify-between pb2">
                    <label>
                        <input type="checkbox" class="mr2" v-model="state.syncEntities" /> Sync Entities with new business
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="flex justify-between pb2">
                    <label>
                        <input type="checkbox" class="mr2" v-model="state.syncInventory" /> Sync Products/Services with new business
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="flex justify-between">
                    <label>
                        <input type="checkbox" class="mr2" v-model="state.syncBankAccounts" /> Sync Bank accounts with new business
                        <span class="checkmark"></span>
                    </label>
                </div>
                <input type="hidden" v-model="state.plan" />
                <input type="hidden" v-model="state.period" />

                <button class="mt3 w-100 submit-btn" type="submit" :disabled="state.disableButton">
                    {{ state.disableButton ? 'Setting up your business, please wait...' : 'Continue' }}
                </button>
            </form>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, reactive, onBeforeMount, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment-timezone'
import { formatDate } from '@/utils/lib'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import InputBox from '@/components/FormComponents/InputBox'
import SelectOption from '@/components/FormComponents/SelectOption'
import Alert from '@/components/Alert'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
    name: 'AddBusiness',
    components: { SettingsWrapper, SelectOption, InputBox, Alert, VueTelInput },

    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const myPlan = computed(() => store?.state?.Settings?.paymentPlan)
        const businesses = computed(() => store?.state?.Settings?.businesses)
        const year = new Date().getFullYear()
        const date = new Date(`${year}-12-31`)
        const newDate = moment(date).format('YYYY-MM-DD')

            const getAllTransactions = () => store.dispatch('DashboardStats/getDashboardStats', state)
            const getGraphOverview = async data => store.dispatch('DashboardStats/getGraphOverview', data)
            const getTodayOverviewStat = async () => store.dispatch('DashboardStats/getTodayOverview')
            const getRecentTransactions = async data => store.dispatch('DashboardStats/getRecentTransactions', data)
            const getTopCustomers = async data => store.dispatch('DashboardStats/getTopCustomers', data)
            const getTopExpenses = async data => store.dispatch('DashboardStats/getTopExpenses', data)
            const getTopEvents = async data => store.dispatch('DashboardStats/getTopEvents', data)
            const getTopProducts = async data => store.dispatch('DashboardStats/getTopProducts', data)

        const initialState = () => ({
            disableButton: false,
            orgName: '',
            phoneNumber: '',
            orgEmail: '',
            business: '',
            typeOfBusiness: '',
            lastFinAccYear: newDate,
            lead: '',
            bankName: '',
            bankAccountNumber: '',
            bankSortCode: '',
            bankAccountName: '',
            orgCategory: '',
            syncEntities: false,
            syncInventory: false,
            syncBankAccounts: false,
            plan: '',
            period: '',
            isEmailValid: null,
            isValidatingEmail: false,
        })

        const state = reactive(initialState())

        const onChange = async (newValue, key) => {
            state[key] = newValue
        }

        const validateEmail = async () => {
            state.isEmailValid = null
            state.isValidatingEmail = true
            await store.dispatch('Auth/validateEmail', state.orgEmail)
                .then(resp => state.isEmailValid = resp)
                .finally(() => state.isValidatingEmail = false)
        }

        const setupBusiness = async () => {
            store.dispatch('Settings/switchBusiness')
        }

        const onSubmit = () => {
            state.disableButton = true

            const { disableButton, isValidatingEmail, ...data } = state

            const payload = {}
            const dataKeys = Object.keys(data)
            dataKeys.forEach(dataKey => {
                if (typeof payload[dataKey] === 'string' || payload[dataKey] instanceof String) {
                    payload[dataKey] = data[dataKey]?.trim()
                } else {
                    payload[dataKey] = data[dataKey]
                }
            })

            store.dispatch('Settings/addBusiness', payload).then(resp => {
                if (resp?.status) {
                    Object.assign(state, initialState())
                    store.dispatch('Settings/switchBusiness', resp?.orgId)
                }
            }).finally(() => state.disableButton = false)
        }

        watch(() => myPlan.value,
            async (curr, prev) => {
                if (myPlan.businessPlan === 'Micro Business Plan') {
                    await router.replace({ name: 'Plan' })
                }
            }
        )

        watch(() => [businesses.value, myPlan.value],
            async (curr, prev) => {
                if (businesses?.length < myPlan?.businesses) {
                    await router.replace({ name: 'Plan' })
                }
            }
        )

        onBeforeMount(() => {
            const { plan, period } = route.query
            state.plan = plan || ''
            state.period = period || ''
        })

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses')
        })

        return { state, newDate, onChange, validateEmail, onSubmit }
    }
}
</script>

<style scoped>
.form-sign2 {
    padding: 11px 10px;
    font-size: 14px;
    border: 0.25px solid #2b59ff;
    border-radius: 5px;
    background: white;
    width: 100%;
}
.tel-div{
    width: 48%;
}
</style>
