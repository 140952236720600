<template>
  <div class="flex flex-column pb3 form-spacing">
    <label class="pb2 font-w2">{{ title }}</label>

    <template v-if="value">
      <input
        class="form-sign w-100"
        :type="type || 'text'"
        :name="name"
        :placeholder="placeholder"
        :id="name"
        :value="value"
        :required="required || false"
        @change="onChange(state.inputValue, name)"
      />
    </template>

    <template v-else>
      <input
        class="form-sign"
        :type="type || 'text'"
        :name="name"
        :placeholder="placeholder"
        :id="name"
        v-model="state.inputValue"
        :required="required || false"
        @change="onChange(state.inputValue, name)"
      />
    </template>
  </div>
  <!--  @change="onChange(state.inputValue, name)"-->
</template>

<script>
import { reactive } from 'vue'
import { formatDate } from '@/utils/lib'

export default {
    name: 'InputBox',
    props: {
        title: String,
        type: String,
        name: String,
        value: String,
        placeholder: String,
        required: Boolean,
        onChange: Function,
    },

    setup() {
        const state = reactive({
            inputValue: '',
        })

        return { state, formatDate }
    },
}
</script>
